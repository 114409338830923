import React from 'react';
import { Card, Image, Space, Row, Col, Form, Tooltip } from 'antd';
import truck from '../../images/1.svg';
import styles from './index.module.less';

const ReportsDashboard = () => {
  const [form] = Form.useForm();

  const Cards = [
    {
      title: 'Temperature & Relative Humidity Monitoring Record',
      icon: truck,
      url: 'log-books-trhmr-list'
    },
    {
      title: 'Usage And Cleaning Record Of Sampling Dispensing Booth & Room',
      icon: truck,
      url: 'uacr-of-sampling-or-dbar-list'
    },
    // {
    //   title: 'Warehouse Area Cleaning Log',
    //   icon: truck,
    //   url: ''
    // },
    // {
    //   title: '70% IPA Preparation Log',
    //   icon: truck,
    //   url: ''
    // },
    {
      title: 'Cleaning Record Of Vaccum Cleaner',
      icon: truck,
      url: 'log-books-vacuum-cleaner-list'
    },
    // {
    //   title: 'Re-Test Material Register',
    //   icon: truck,
    //   url: ''
    // },
    {
      title: 'Purified Water Collection Log',
      icon: truck,
      url: 'log-books-purified-water-collection-list'
    },
    {
      title: 'Cleaning Record Of Dispensing Aids',
      icon: truck,
      url: 'log-books-dispensing-aids-list'
    },
    {
      title: 'Cleaning Record Of Solvent Transfer Pump',
      icon: truck,
      url: 'log-books-solvent-transfer-pump-list'
    },
    {
      title: 'DISPATCH CHECK LIST FOR FINISHED GOODS',
      icon: truck,
      url: 'disapatch-check-list'
    },
    {
      title: 'COMMERCIAL FINISHED PRODUCT RECEIPT',
      icon: truck,
      url: 'commercial-finished-product'
    },
    {
      title: 'COMMERCIAL FINISHED PRODUCT DISTRIBUTION',
      icon: truck,
      url: 'distribution-details'
    },
    {
      title: 'EXIBHIT FINISHED PRODUCT RECEIPT',
      icon: truck,
      url: 'exibhit-finished'
    },
    {
      title: 'EXIBHIT FINISHED PRODUCT ISSUANCE',
      icon: truck,
      url: 'exibhit-issuanced'
    },
    {
      title: 'Returnable Gate Pass',
      icon: truck,
      url: 'returnable-gate-pass-list'
    },
    {
      title: 'Non Returnable Gate Pass',
      icon: truck,
      url: 'non-returnable-gate-pass-list'
    },
    {
      title: 'Returnable Gate Pass Register',
      icon: truck,
      url: 'returnable-gate-pass-register-list'
    },
    {
      title: 'Non Returnable Gate Pass Register',
      icon: truck,
      url: 'non-returnable-gate-pass-register-list'
    }
    // {
    //   title: 'Vehicle Stissorage Area',
    //   icon: truck,
    //   url: ''
    // }
  ];
  const cardView = Cards;
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={[24, 24]}>
          {cardView?.map((items, index) => {
            return (
              <Col
                xs={4}
                xl={4}
                md={6}
                sm={6}
                span={6}
                className={`${styles.cardbody1} mb-2`}
                key={index}
              >
                <Card
                  hoverable
                  className={styles.cardbody}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = items.url;
                  }}
                >
                  <Space direction="vertical">
                    <Image
                      src={items.icon}
                      preview={false}
                      style={{ width: '80% !important' }}
                      className={styles.stickers}
                    />
                    <span>
                      {items?.title?.length > 40 ? (
                        <Tooltip title={items.title}>
                          {items.title.substring(0, 40) + '...'}
                        </Tooltip>
                      ) : (
                        items.title
                      )}
                    </span>
                  </Space>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Form>
    </>
  );
};

export default ReportsDashboard;
