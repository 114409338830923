import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Login from './features/login';
import LoginFace from './features/login_face_auth';
import Loader from './common/Loader';
import DashBoard from './features/dashboard';
import SuperAdminDashBoard from './features/superAdminDashboard';
import List from './features/material/components/ListofMaterials';
import MaterialCreate from './features/material';
import Checklist from './features/lineclearancechecklist';
import QCChecklist from './features/qc/components/lineclearancechecklist';
import View from './features/material/components/viewMaterialPage';
import ListofDispenseMaterials from './features/material/components/ListofDispenseMaterials';
import ListofQaDispenseMaterials from './features/material/components/ListofQaDispenseMaterials';
// import WeighingReport from './features/material/components/WeighingReport';
// import DamageReport from './features/material/components/DamageReport';
import Lable from './features/label';
import VehicleStorage from './features/material/components/VehicleStorage';
import InvoiceDetails from './features/material/components/InvoiceDetailsPage';
import BatchDetails from './features/batchdetails';
import DamageReport from './features/damagereport';
import WeighingReport from './features/weighingreport';
import GenerateMRN from './features/generateMrn';
import PendingReceipts from './features/pendingReceipts';
import QClist from './features/qc';
import DOCMissing from './features/qc/components/DocMissingPage';
import QCDamage from './features/qc/components/DamageReportPage';
import StorageMissing from './features/qc/components/StorageMissingPage';
import QCDiscrepancy from './features/qc/components/DiscepancyReportPage';
import UPnextReceipts from './features/upnextReceipts';
import LandingPage from './features/MoveMaterials';
import UpdateBin from './features/MoveMaterials/components/updateBinPage';
import TrackBin from './features/MoveMaterials/components/trackbinPage';
import FreeContainers from './features/MoveMaterials/components/binfreeContainersPage';
import ContainerHistory from './features/MoveMaterials/components/ContainerHistory';
import UpdateNetWeights from './features/material/components/UpdateNetWeights';
import Dispensing from './features/dispensing';
import DispensingList from './features/dispensing/components/List';
import DispensingMaterial from './features/dispensing/components/DispenseMaterialPage';
import DispensingView from './features/dispensing/components/DispensingView';
import QCApproval from './features/qa';
import StockCard from './features/stockCard';
import DispenseLable from './features/dispenseLabel';
import WastagePage from './features/wastageMaterials';
import SamplingPage from './features/samplingMaterials';
import BackendMRN from './features/backendMRN';
import StockCardIssuance from './features/stockCardIssuance';
import StockReconsilation from './features/stockReconsilation';
import WastageReconsilation from './features/wastageReconsilation';
import ReconsilationRequest from './features/ReconsiliationRequest';
import WastageApproval from './features/wastageReconsilation/components/WastageApproved';
import SamplesList from './features/samplingMaterials/components/samplingList';
import UsersList from './features/admin';
import UsersListViewOnly from './features/usersListViewOnly';
import UserCreation from './features/admin/components/createUser';
import ForgotPassword from './features/forgotPassword';
import ChangePassword from './features/changePassword';
import ResetPassword from './features/resetPassword';
import AudiTrails from './features/auditTrails';
import StockReport from './features/auditTrails/components/stockReport';
import Vendors from './features/qc/components/VendorsList';
import VendorMaterials from './features/qc/components/VendorCards';
import VendorCreation from './features/qc/components/CreateVendor';
import MaterialList from './features/qc/components/MaterialList';
import MaterialCreation from './features/qc/components/CreateMaterial';
import ViewVendor from './features/qc/components/ViewVendor';
import ReportDashboard from './features/auditTrails/components/ReportsDashboard';
import BatchesList from './features/qa/components/QAListpage';
import ProductDetails from './features/dispensing/components/ProductDetailsPage';
import CreateProduct from './features/prodUser';
import Granulation from './features/prodUser/components/granulation';
import Sifting from './features/prodUser/components/sifting_and_milling';
import ExtraGranulation from './features/prodUser/components/extraGranulation';
import Blending from './features/prodUser/components/blending';
import ProdSampling from './features/prodUser/components/prodSampling';
import Yield from './features/prodUser/components/yieldReconcilation';
import BlendDistrubution from './features/prodUser/components/blendDistrubution';
import ProductList from './features/qc/components/ProductsList';
import AssayCalculation from './features/dispensing/components/AssayCalculation';
import PrintAssay from './features/dispensing/components/PrintAssay';
import DispenseManual from './features/dispensing/components/ManualDispense';
import TestRequestForm from './features/qa/components/TestRequestForm';
import ProductCreate from './features/prodUser/components/createProductNewPage';
import BMRCreate from './features/prodUser/components/CreateBMR';
import MaterialRequests from './features/returnRequest';
import CreateReturnQty from './features/returnRequest/components/createReturnQty';
import Weighing from './features/prodUser/components/weighing';
import BMRConfig from './features/prodUser/components/configGranulation';
import BMRParams from './features/prodUser/components/configProcess';
import BMRParamsView from './features/prodUser/components/configProcessView';
import ExecuteBMR from './features/prodUser/components/executeBMR';
import ViewExecuteBMR from './features/prodUser/components/viewExecuteBMR';
import LogBookList from './features/qc/components/LogbookList';
import CreateLog from './features/qc/components/CreateLog';
import ViewEditLog from './features/qc/components/ViewEditLog';
import Invoicedetailslist from './features/financeUser';
import InvoiceCreate from './features/financeUser/components/CreateInvoice';
import InvoiceView from './features/financeUser/components/ViewEditInvoice';
import FGBatchReport from './features/financeUser/components/fgBatchReport';
import COGSReport from './features/financeUser/components/COGSReport';
import ViewAuditTrailsPage from './features/auditTrails/components/ViewAuditTrailsPage';
import OtherReports from './features/auditTrails/components/OtherReports';
import LogBooks from './features/logBooks';
import TrhmrLogBooksList from './features/logBooks/components/trhmrList';
import TrhmrLogBookById from './features/logBooks/components/trhmrById';
import TrhmrLogBookCreate from './features/logBooks/components/trhmrCreate';
import CodetoCode from './features/material/components/CodetoCode';
import CreateCodetoCode from './features/material/components/CreateCodetoCode';
import UacrOfSamplingOrDbarList from './features/logBooks/components/uacrosOrDbarList';
import UacrOfSamplingOrDbarById from './features/logBooks/components/uacrosOrDbarById';
import UacrOfSamplingOrDbarCreate from './features/logBooks/components/uacrosOrDbarCreate';
import VacuumCleaningList from './features/logBooks/components/vacuumList';
import VacuumCleaningCreate from './features/logBooks/components/vacuumCreate';
import WaterCollectionList from './features/logBooks/components/waterCollectionList';
import WaterCollectionCreate from './features/logBooks/components/waterCollectionCreate';
import DispensingAidsList from './features/logBooks/components/dispensingAidsList';
import DispensingAidsCreate from './features/logBooks/components/dispensingAidsCreate';
import SolventTransferPumpList from './features/logBooks/components/solventTransferPumpList';
import SolventTransferPumpCreate from './features/logBooks/components/solventTransferPumpCreate';
import Departments from './features/admin/components/Departments';
import Designations from './features/admin/components/Designations';
import QALineClearence from './features/prodUser/components/LineClearence';
import QALineClearenceList from './features/prodUser/components/LineClearenceList';
import DispatchCheckList from './features/logBooks/components/dispatchCheckListReport';
import CommercialFinishedProduct from './features/logBooks/components/commercialFinishedProduct';
import DistributionDetails from './features/logBooks/components/distributionDetails';
import ExibhitFinished from './features/logBooks/components/exibhitFinished';
import ExibhitIssuanced from './features/logBooks/components/exibhitFinishedIssuence';
import ProductsMasterList from './features/prodUser/components/ProductsMasterList';
import CreateProductMaster from './features/prodUser/components/createProductMaster';
import EBMRMasterList from './features/prodUser/components/EBMRMasterList';
import CreateEBMRMaster from './features/prodUser/components/createEBMRMaster';
import ViewProductMaster from './features/prodUser/components/createProductMaster';
import ViewEbmrMaster from './features/prodUser/components/createEBMRMaster';
import ReturnableGatePass from './features/logBooks/components/returnableGatePass';
import CreateReturnableGatePass from './features/logBooks/components/createReturnableGatepass';
import NonReturnableGatePass from './features/logBooks/components/NonreturnableGatePass';
import CreateNonReturnableGatePass from './features/logBooks/components/createNonReturnableGatepass';
import ReturnableGatePassRegister from './features/logBooks/components/returnableGatePassRegister';
import CreateReturnableGatepassRegister from './features/logBooks/components/createReturnableGatepassRegister';
import NonreturnableGatePassRegister from './features/logBooks/components/NonreturnableGatePassRegister';
import CreateNonReturnableGatepassRegister from './features/logBooks/components/createNonReturnableGatePassRegister';

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'user':
      return '/dashboard';
    case 'qc_initiate':
      return '/dashboard';
    case 'prod_user':
      return '/dashboard';
    case 'update_admin':
      return '/backend_mrn';
    case 'ppic_user':
      return '/dashboard';
    case 'qa_super_user':
      return '/super_admin_dashboard';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }
  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();

  const { loggedIn, user } = getLoggedInUser();

  let goTo = roleBasedNavigation(user?.role_id || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const user = getLoggedInUser();
  let goTo = user?.role ? roleBasedNavigation(user?.role || '') : '/dashboard';

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <Login />
            </AuthChecker>
          }
        />
        <Route
          path="login-face"
          element={
            <AuthChecker>
              <LoginFace />
            </AuthChecker>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <DashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="create"
          element={
            <PrivateRoute>
              <MaterialCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="create-checklist/:id"
          element={
            <PrivateRoute>
              <Checklist />
            </PrivateRoute>
          }
        />
        <Route
          path="qc-create-checklist/:id"
          element={
            <PrivateRoute>
              <QCChecklist />
            </PrivateRoute>
          }
        />
        <Route
          path="dispense-material-list"
          element={
            <PrivateRoute>
              <ListofDispenseMaterials />
            </PrivateRoute>
          }
        />
        <Route
          path="dispense-qa-materials-list"
          element={
            <PrivateRoute>
              <ListofQaDispenseMaterials />
            </PrivateRoute>
          }
        />
        <Route
          path="list"
          element={
            <PrivateRoute>
              <List />
            </PrivateRoute>
          }
        />
        <Route
          path="view/:id"
          element={
            <PrivateRoute>
              <View />
            </PrivateRoute>
          }
        />
        <Route
          path="wighing_report/:id"
          element={
            <PrivateRoute>
              <WeighingReport />
            </PrivateRoute>
          }
        />
        <Route
          path="damage_report/:id"
          element={
            <PrivateRoute>
              <DamageReport />
            </PrivateRoute>
          }
        />
        <Route
          path="material/:id"
          element={
            <AuthChecker>
              <Lable />
            </AuthChecker>
          }
        />
        <Route
          path="vehicle-storage/:id"
          element={
            <PrivateRoute>
              <VehicleStorage />
            </PrivateRoute>
          }
        />
        <Route
          path="invoice-details/:id"
          element={
            <PrivateRoute>
              <InvoiceDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="batch-details/:id"
          element={
            <PrivateRoute>
              <BatchDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="damage-report"
          element={
            <PrivateRoute>
              <DamageReport />
            </PrivateRoute>
          }
        />
        <Route
          path="weighing-report"
          element={
            <PrivateRoute>
              <WeighingReport />
            </PrivateRoute>
          }
        />
        <Route
          path="generate-mrn/:id"
          element={
            <PrivateRoute>
              <GenerateMRN />
            </PrivateRoute>
          }
        />
        <Route
          path="pending-receipts"
          element={
            <PrivateRoute>
              <PendingReceipts />
            </PrivateRoute>
          }
        />
        <Route
          path="qc-list"
          element={
            <PrivateRoute>
              <QClist />
            </PrivateRoute>
          }
        />
        <Route
          path="doc-missing/:id"
          element={
            <PrivateRoute>
              <DOCMissing />
            </PrivateRoute>
          }
        />
        <Route
          path="storage-missing/:id"
          element={
            <PrivateRoute>
              <StorageMissing />
            </PrivateRoute>
          }
        />
        <Route
          path="qc-damage-report/:id"
          element={
            <PrivateRoute>
              <QCDamage />
            </PrivateRoute>
          }
        />
        <Route
          path="qc-discrepancy-report/:id"
          element={
            <PrivateRoute>
              <QCDiscrepancy />
            </PrivateRoute>
          }
        />
        <Route
          path="upnext-receipts"
          element={
            <PrivateRoute>
              <UPnextReceipts />
            </PrivateRoute>
          }
        />
        <Route
          path="landingpage"
          element={
            <PrivateRoute>
              <LandingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="updatebin"
          element={
            <PrivateRoute>
              <UpdateBin />
            </PrivateRoute>
          }
        />
        <Route
          path="trackbin"
          element={
            <PrivateRoute>
              <TrackBin />
            </PrivateRoute>
          }
        />
        <Route
          path="free-containers"
          element={
            <PrivateRoute>
              <FreeContainers />
            </PrivateRoute>
          }
        />
        <Route
          path="container-history"
          element={
            <PrivateRoute>
              <ContainerHistory />
            </PrivateRoute>
          }
        />
        <Route
          path="update-net-weights/:id"
          element={
            <PrivateRoute>
              <UpdateNetWeights />
            </PrivateRoute>
          }
        />
        <Route
          path="dispensing"
          element={
            <PrivateRoute>
              <Dispensing />
            </PrivateRoute>
          }
        />
        <Route
          path="dispensing/:id"
          element={
            <PrivateRoute>
              <DispensingView />
            </PrivateRoute>
          }
        />
        <Route
          path="dispensing-list"
          element={
            <PrivateRoute>
              <DispensingList />
            </PrivateRoute>
          }
        />
        <Route
          path="dispensing-material/:id"
          element={
            <PrivateRoute>
              <DispensingMaterial />
            </PrivateRoute>
          }
        />
        <Route
          path="qc-approval"
          element={
            <PrivateRoute>
              <QCApproval />
            </PrivateRoute>
          }
        />
        <Route
          path="stock-card"
          element={
            <PrivateRoute>
              <StockCard />
            </PrivateRoute>
          }
        />
        <Route
          path="dispense"
          element={
            <AuthChecker>
              <DispenseLable />
            </AuthChecker>
          }
        />
        <Route
          path="wastage"
          element={
            <PrivateRoute>
              <WastagePage />
            </PrivateRoute>
          }
        />
        <Route
          path="sampling"
          element={
            <PrivateRoute>
              <SamplingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="backend_mrn"
          element={
            <PrivateRoute>
              <BackendMRN />
            </PrivateRoute>
          }
        />
        <Route
          path="stock-card-issuance/:id"
          element={
            <PrivateRoute>
              <StockCardIssuance />
            </PrivateRoute>
          }
        />
        <Route
          path="stock-reconsilation"
          element={
            <PrivateRoute>
              <StockReconsilation />
            </PrivateRoute>
          }
        />
        <Route
          path="wastage-reconsilation"
          element={
            <PrivateRoute>
              <WastageReconsilation />
            </PrivateRoute>
          }
        />
        <Route
          path="reconsiliation_request"
          element={
            <PrivateRoute>
              <ReconsilationRequest />
            </PrivateRoute>
          }
        />
        <Route
          path="wastageApproval"
          element={
            <PrivateRoute>
              <WastageApproval />
            </PrivateRoute>
          }
        />
        <Route
          path="samples-list"
          element={
            <PrivateRoute>
              <SamplesList />
            </PrivateRoute>
          }
        />
        <Route
          path="users-list"
          element={
            <PrivateRoute>
              <UsersList />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path="users-list-view-only"
          element={
            <PrivateRoute>
              <UsersListViewOnly />
            </PrivateRoute>
          }
        />
        <Route
          path="user-creation"
          element={
            <PrivateRoute>
              <UserCreation />
            </PrivateRoute>
          }
        />
        <Route
          path="audit-trails"
          element={
            <PrivateRoute>
              <AudiTrails />
            </PrivateRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <AuthChecker>
              <ForgotPassword />
            </AuthChecker>
          }
        />
        <Route
          path="change-password"
          element={
            <AuthChecker>
              <ChangePassword />
            </AuthChecker>
          }
        />
        <Route
          path="reset-password"
          element={
            <AuthChecker>
              <ResetPassword />
            </AuthChecker>
          }
        />
        <Route
          path="vendors"
          element={
            <PrivateRoute>
              <Vendors />
            </PrivateRoute>
          }
        />
        <Route
          path="vendor-material"
          element={
            <PrivateRoute>
              <VendorMaterials />
            </PrivateRoute>
          }
        />
        <Route
          path="create-vendor"
          element={
            <PrivateRoute>
              <VendorCreation />
            </PrivateRoute>
          }
        />
        <Route
          path="view-vendor"
          element={
            <PrivateRoute>
              <ViewVendor />
            </PrivateRoute>
          }
        />
        <Route
          path="material-list"
          element={
            <PrivateRoute>
              <MaterialList />
            </PrivateRoute>
          }
        />
        <Route
          path="create-material"
          element={
            <PrivateRoute>
              <MaterialCreation />
            </PrivateRoute>
          }
        />
        <Route
          path="audit-trails-dashboard"
          element={
            <PrivateRoute>
              <ReportDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="batches-list"
          element={
            <PrivateRoute>
              <BatchesList />
            </PrivateRoute>
          }
        />
        <Route
          path="product-details/:id"
          element={
            <PrivateRoute>
              <ProductDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="create-product"
          element={
            <PrivateRoute>
              <CreateProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="granulation/:id"
          element={
            <PrivateRoute>
              <Granulation />
            </PrivateRoute>
          }
        />
        <Route
          path="extra-granulation/:id"
          element={
            <PrivateRoute>
              <ExtraGranulation />
            </PrivateRoute>
          }
        />
        <Route
          path="sifting-and-milling/:id"
          element={
            <PrivateRoute>
              <Sifting />
            </PrivateRoute>
          }
        />
        <Route
          path="blending/:id"
          element={
            <PrivateRoute>
              <Blending />
            </PrivateRoute>
          }
        />
        <Route
          path="prod-sampling/:id"
          element={
            <PrivateRoute>
              <ProdSampling />
            </PrivateRoute>
          }
        />
        <Route
          path="yield-reconcilation/:id"
          element={
            <PrivateRoute>
              <Yield />
            </PrivateRoute>
          }
        />
        <Route
          path="blend-distrubution/:id"
          element={
            <PrivateRoute>
              <BlendDistrubution />
            </PrivateRoute>
          }
        />
        <Route
          path="product-list"
          element={
            <PrivateRoute>
              <ProductList />
            </PrivateRoute>
          }
        />
        <Route
          path="assay-calculation/:id"
          element={
            <PrivateRoute>
              <AssayCalculation />
            </PrivateRoute>
          }
        />
        <Route
          path="print-assay/:id"
          element={
            <PrivateRoute>
              <PrintAssay />
            </PrivateRoute>
          }
        />
        <Route
          path="manual_dispense/:id"
          element={
            <PrivateRoute>
              <DispenseManual />
            </PrivateRoute>
          }
        />
        <Route
          path="test_request_form"
          element={
            <PrivateRoute>
              <TestRequestForm />
            </PrivateRoute>
          }
        />
        <Route
          path="product-create"
          element={
            <PrivateRoute>
              <ProductCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="bmr-create/:id"
          element={
            <PrivateRoute>
              <BMRCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="bmr-config/:id"
          element={
            <PrivateRoute>
              <BMRConfig />
            </PrivateRoute>
          }
        />
        <Route
          path="bmr-params/:id"
          element={
            <PrivateRoute>
              <BMRParams />
            </PrivateRoute>
          }
        />
        <Route
          path="bmr-params-view/:id"
          element={
            <PrivateRoute>
              <BMRParamsView />
            </PrivateRoute>
          }
        />
        <Route
          path="material-return-request"
          element={
            <PrivateRoute>
              <MaterialRequests />
            </PrivateRoute>
          }
        />
        <Route
          path="create-return-qty"
          element={
            <PrivateRoute>
              <CreateReturnQty />
            </PrivateRoute>
          }
        />
        <Route
          path="weighing/:id"
          element={
            <PrivateRoute>
              <Weighing />
            </PrivateRoute>
          }
        />
        <Route
          path="execute-bmr/:id"
          element={
            <PrivateRoute>
              <ExecuteBMR />
            </PrivateRoute>
          }
        />
        <Route
          path="view-execute-bmr/:id"
          element={
            <PrivateRoute>
              <ViewExecuteBMR />
            </PrivateRoute>
          }
        />
        <Route
          path="stock-report"
          element={
            <PrivateRoute>
              <StockReport />
            </PrivateRoute>
          }
        />
        <Route
          path="log-book-list"
          element={
            <PrivateRoute>
              <LogBookList />
            </PrivateRoute>
          }
        />
        <Route
          path="create-log"
          element={
            <PrivateRoute>
              <CreateLog />
            </PrivateRoute>
          }
        />
        <Route
          path="view-log/:id"
          element={
            <PrivateRoute>
              <ViewEditLog />
            </PrivateRoute>
          }
        />
        <Route
          path="invoice-details-list"
          element={
            <PrivateRoute>
              <Invoicedetailslist />
            </PrivateRoute>
          }
        />
        <Route
          path="create-invoice"
          element={
            <PrivateRoute>
              <InvoiceCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="view-invoice/:id"
          element={
            <PrivateRoute>
              <InvoiceView />
            </PrivateRoute>
          }
        />
        <Route
          path="fg-batch-report"
          element={
            <PrivateRoute>
              <FGBatchReport />
            </PrivateRoute>
          }
        />
        <Route
          path="cogs-report"
          element={
            <PrivateRoute>
              <COGSReport />
            </PrivateRoute>
          }
        />
        <Route
          path="view-audit-trails"
          element={
            <PrivateRoute>
              <ViewAuditTrailsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="other-reports"
          element={
            <PrivateRoute>
              <OtherReports />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books"
          element={
            <PrivateRoute>
              <LogBooks />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-trhmr-list"
          element={
            <PrivateRoute>
              <TrhmrLogBooksList />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-trhmr-list/:id"
          element={
            <PrivateRoute>
              <TrhmrLogBookById />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-trhmr-create"
          element={
            <PrivateRoute>
              <TrhmrLogBookCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="code-to-code-list"
          element={
            <PrivateRoute>
              <CodetoCode />
            </PrivateRoute>
          }
        />
        <Route
          path="create-code-to-code"
          element={
            <PrivateRoute>
              <CreateCodetoCode />
            </PrivateRoute>
          }
        />
        <Route
          path="uacr-of-sampling-or-dbar-list"
          element={
            <PrivateRoute>
              <UacrOfSamplingOrDbarList />
            </PrivateRoute>
          }
        />
        <Route
          path="uacr-of-sampling-or-dbar-list/:id"
          element={
            <PrivateRoute>
              <UacrOfSamplingOrDbarById />
            </PrivateRoute>
          }
        />
        <Route
          path="uacr-of-sampling-or-dbar-create"
          element={
            <PrivateRoute>
              <UacrOfSamplingOrDbarCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-vacuum-cleaner-list"
          element={
            <PrivateRoute>
              <VacuumCleaningList />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-vacuum-cleaner-create"
          element={
            <PrivateRoute>
              <VacuumCleaningCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-purified-water-collection-list"
          element={
            <PrivateRoute>
              <WaterCollectionList />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-purified-water-collection-create"
          element={
            <PrivateRoute>
              <WaterCollectionCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-dispensing-aids-list"
          element={
            <PrivateRoute>
              <DispensingAidsList />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-dispensing-aids-create"
          element={
            <PrivateRoute>
              <DispensingAidsCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-solvent-transfer-pump-list"
          element={
            <PrivateRoute>
              <SolventTransferPumpList />
            </PrivateRoute>
          }
        />
        <Route
          path="log-books-solvent-transfer-pump-create"
          element={
            <PrivateRoute>
              <SolventTransferPumpCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="departments-list"
          element={
            <PrivateRoute>
              <Departments />
            </PrivateRoute>
          }
        />
        <Route
          path="designations-list"
          element={
            <PrivateRoute>
              <Designations />
            </PrivateRoute>
          }
        />
        <Route
          path="qa-lc-list/:id"
          element={
            <PrivateRoute>
              <QALineClearenceList />
            </PrivateRoute>
          }
        />
        <Route
          path="qa-lineclearence/:id"
          element={
            <PrivateRoute>
              <QALineClearence />
            </PrivateRoute>
          }
        />
        <Route
          path="super_admin_dashboard"
          element={
            <PrivateRoute>
              <SuperAdminDashBoard />
            </PrivateRoute>
          }
        />
        <Route
          path="disapatch-check-list"
          element={
            <PrivateRoute>
              <DispatchCheckList />
            </PrivateRoute>
          }
        />
        <Route
          path="commercial-finished-product"
          element={
            <PrivateRoute>
              <CommercialFinishedProduct />
            </PrivateRoute>
          }
        />
        <Route
          path="distribution-details"
          element={
            <PrivateRoute>
              <DistributionDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="exibhit-finished"
          element={
            <PrivateRoute>
              <ExibhitFinished />
            </PrivateRoute>
          }
        />
        <Route
          path="exibhit-issuanced"
          element={
            <PrivateRoute>
              <ExibhitIssuanced />
            </PrivateRoute>
          }
        />
        <Route
          path="product-masters-list"
          element={
            <PrivateRoute>
              <ProductsMasterList />
            </PrivateRoute>
          }
        />
        <Route
          path="product-masters-create"
          element={
            <PrivateRoute>
              <CreateProductMaster />
            </PrivateRoute>
          }
        />
        <Route
          path="master-product-view/:id"
          element={
            <PrivateRoute>
              <ViewProductMaster />
            </PrivateRoute>
          }
        />
        <Route
          path="ebmr-master-list"
          element={
            <PrivateRoute>
              <EBMRMasterList />
            </PrivateRoute>
          }
        />
        <Route
          path="ebmr-masters-create"
          element={
            <PrivateRoute>
              <CreateEBMRMaster />
            </PrivateRoute>
          }
        />
        <Route
          path="ebmr-masters-create/:id"
          element={
            <PrivateRoute>
              <ViewEbmrMaster />
            </PrivateRoute>
          }
        />
        <Route
          path="returnable-gate-pass-list"
          element={
            <PrivateRoute>
              <ReturnableGatePass />
            </PrivateRoute>
          }
        />
        <Route
          path="create-returnable-gatepass"
          element={
            <PrivateRoute>
              <CreateReturnableGatePass />
            </PrivateRoute>
          }
        />
        <Route
          path="non-returnable-gate-pass-list"
          element={
            <PrivateRoute>
              <NonReturnableGatePass />
            </PrivateRoute>
          }
        />
        <Route
          path="create-non-returnable-gatepass"
          element={
            <PrivateRoute>
              <CreateNonReturnableGatePass />
            </PrivateRoute>
          }
        />
        <Route
          path="returnable-gate-pass-register-list"
          element={
            <PrivateRoute>
              <ReturnableGatePassRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="create-returnable-gatepass-register"
          element={
            <PrivateRoute>
              <CreateReturnableGatepassRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="non-returnable-gate-pass-register-list"
          element={
            <PrivateRoute>
              <NonreturnableGatePassRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="create-non-returnable-gatepass-register"
          element={
            <PrivateRoute>
              <CreateNonReturnableGatepassRegister />
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
