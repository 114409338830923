import apiClient from 'utilities/apiClient';

export const createReturns = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.returnable}/${url}`, payload, true);
};

export const getAllreturn = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.returnable}/${url}`, payload, true);
};

export const getAllnonreturn = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.nonreturnable}/${url}`, payload, true);
};

export const createnonReturns = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.nonreturnable}/${url}`,
    payload,
    true
  );
};

export const createReturnsregister = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.returnabledate}/${url}`,
    payload,
    true
  );
};

export const getAllReturnRegister = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.returnabledate}/${url}`,
    payload,
    true
  );
};

export const createnonReturnsregister = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.nonreturnabledate}/${url}`,
    payload,
    true
  );
};

export const getAllnonReturnRegister = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.nonreturnabledate}/${url}`,
    payload,
    true
  );
};
